import { AnimationStyleMetadata } from '@angular/animations';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnInit,
  Signal,
  computed,
  input,
  model,
  viewChild,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Map, MapEvent } from 'ol';
import { fromEvent, switchMap } from 'rxjs';

@Component({
    selector: 'pdf-map-scale',
    templateUrl: './pdf-map-scale.component.html',
    styleUrls: ['./pdf-map-scale.component.css'],
    imports: [CommonModule, CdkDrag]
})
export class PdfMapScaleComponent {
  mapScale = input<any>();

}
