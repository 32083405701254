import { CdkDrag, Point } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  input,
  model,
  viewChild,
} from '@angular/core';

@Component({
    selector: 'pdf-draggable',
    templateUrl: './pdf-draggable.component.html',
    styleUrls: ['./pdf-draggable.component.css'],
    imports: [CommonModule, CdkDrag]
})
export class PdfDraggableComponent implements OnInit {
  cdkDragElement = viewChild.required(CdkDrag);

  position = input.required<Point>();

  @Output() positionChanged = new EventEmitter<Point>();

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.cdkDragElement().ended.subscribe(() => {
      this.positionChanged.emit(this.cdkDragElement().getFreeDragPosition());
    });
  }
}
