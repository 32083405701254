import { CommonModule } from '@angular/common';
import { Component, OnInit, input, model } from '@angular/core';
import { Property } from 'src/app/map/features/property/data-access/models/property.model';
import { FormatStringPipe } from 'src/app/shared/pipes/formatString.pipe';

@Component({
    selector: 'app-pdf-property-details',
    templateUrl: './pdf-property-details.component.html',
    styleUrls: ['./pdf-property-details.component.css'],
    imports: [CommonModule, FormatStringPipe]
})
export class PdfPropertyDetailsComponent implements OnInit {
  selectedFields = input.required<string>();
  property = input.required<Property>();



  constructor() {}

  ngOnInit() {}
}
